import { Store } from '@shared/types/store';

export const mapStore = ({ storeId, key, name }: Store): EntityStore => {
  return { id: storeId as string, key, name: name ?? key ?? '' };
};

interface EntityStore {
  storeId?: string;
  id: string;
  name: string;
  key: string;
}
