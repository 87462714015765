import { useCallback } from 'react';
import { useParams } from 'next/navigation';
import { Currency } from '@/types/currency';
import { Address, Address as AccountAddress } from '@shared/types/account';

const useFormat = () => {
  const { locale } = useParams();

  const formatCurrency = useCallback(
    (price: number, currency: Currency) => {
      const intlLocaleMapping = { en: 'en-US', fr: 'fr-FR' };

      return new Intl.NumberFormat(
        intlLocaleMapping[(locale as string).substring(0, 2) as keyof typeof intlLocaleMapping],
        {
          style: 'currency',
          currency,
        },
      ).format(price);
    },
    [locale],
  );

  const formatName = useCallback((firstname: string | undefined, lastname: string | undefined) => {
    if (!firstname && !lastname) {
      return '';
    }

    return `${firstname ?? ''} ${lastname ?? ''}`;
  }, []);

  const formatAccountAddress = useCallback((address: AccountAddress) => {
    return `${address.streetNumber} ${address.streetName}, ${address.postalCode} ${address.city}`.replace(
      /[^\S\r\n]+/g,
      ' ',
    );
  }, []);

  const formatBusinessUnitAddress = useCallback((address: Address) => {
    return `${address.line1}, ${address.zip} ${address.city}`.replace(/[^\S\r\n]+/g, ' ');
  }, []);

  const formatAddress = useCallback((address: Address) => {
    return `${address.name} ${address.careOf ? `(c/o ${address.careOf})` : ''}\n${address.line1}\n${address.zip} ${
      address.city
    }, ${address.country}`.replace(/[^\S\r\n]+/g, ' ');
  }, []);

  const formatLocalDate = useCallback((date: Date | string) => {
    const dateToFormat = date instanceof Date ? date : new Date(date);

    const [day, month, year] = [
      dateToFormat.getDate().toString().padStart(2, '0'),
      (dateToFormat.getMonth() + 1).toString().padStart(2, '0'),
      dateToFormat.getFullYear().toString(),
    ];

    return `${day}-${month}-${year}`;
  }, []);

  return {
    formatCurrency,
    formatName,
    formatAccountAddress,
    formatAddress,
    formatLocalDate,
    formatBusinessUnitAddress,
  };
};

export default useFormat;
