import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Category } from '@shared/types/product';
import { useCategories } from '@/lib/hooks/useCategories';
import { CategoriesContextShape } from './types'; // Typage spécifique pour le contexte

const initialState = {} as CategoriesContextShape;
export const CategoriesContext = createContext(initialState);

const CategoriesProvider = ({
  children,
  businessUnitKey,
  storeKey,
}: {
  children: React.ReactNode;
  businessUnitKey: string | undefined;
  storeKey: string | undefined;
}) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoadingCategories] = useState(false);

  const categoryOptions = useMemo(() => {
    return businessUnitKey
      ? {
          businessUnitKey,
          storeKey,
          format: 'tree',
          limit: 500,
        }
      : undefined;
  }, [businessUnitKey, storeKey]);

  const { categories: fetchedCategories } = useCategories(categoryOptions);

  useEffect(() => {
    if (businessUnitKey && storeKey) {
      setIsLoadingCategories(true);

      if (JSON.stringify(categories) !== JSON.stringify(fetchedCategories)) {
        setCategories(fetchedCategories || []);
      }
      setIsLoadingCategories(false);
    }
  }, [businessUnitKey, fetchedCategories, categories]);

  const value = useMemo(
    () => ({
      categories,
      isLoading,
    }),
    [categories, isLoading],
  );

  return <CategoriesContext.Provider value={value}>{children}</CategoriesContext.Provider>;
};

export const useCategoriesByBUContext = () => {
  const context = useContext(CategoriesContext);
  if (!context) {
    throw new Error('useCategoriesContext must be used within a CategoriesProvider');
  }
  return context;
};

export default CategoriesProvider;
