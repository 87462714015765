import { BusinessUnit } from '@shared/types/business-unit/BusinessUnit';
import { mapAddress } from './map-address';
import { mapStore } from './map-store';
import { Store } from '@shared/types/store';
import { Address } from '@shared/types/account';

export const mapBusinessUnit = ({
  businessUnitId,
  key,
  name,
  contactEmail,
  addresses,
  stores,
  topLevelUnit,
  parentUnit,
  defaultShippingMethod,
}: BusinessUnit): EntityBusinessUnit => {
  return {
    id: businessUnitId as string,
    name: name ?? '',
    key: key ?? '',
    email: contactEmail ?? '',
    addresses: (addresses ?? []).map(mapAddress),
    stores: (stores ?? []).map(mapStore),
    topLevelUnit: topLevelUnit && topLevelUnit.key !== key ? mapBusinessUnit(topLevelUnit) : undefined,
    parentUnit: parentUnit && parentUnit.key !== key ? mapBusinessUnit(parentUnit) : undefined,
    defaultShippingMethod: defaultShippingMethod ?? undefined,
  };
};

interface EntityBusinessUnit {
  id: string;
  name: string;
  key: string;
  email: string;
  stores?: Store[];
  addresses: Address[];
  topLevelUnit?: BusinessUnit;
  parentUnit?: BusinessUnit;
  defaultShippingMethod?: string;
}
