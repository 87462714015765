import { Store } from '@shared/types/store/Store';
import { useStoreAndBusinessUnits } from '@/providers/store-and-business-units';
import { sdk } from '@/sdk';
import useSWR from 'swr';
import { StoreChannels } from '@shared/types/store/Channel';
import { mapBusinessUnit } from '@/utils/mappers/map-business-unit';
import useBusinessUnits from '../useBusinessUnits';
import { useCustomObjects } from '@/lib/hooks/useCustomObjects';
import { ChannelVisibility } from '@shared/types/custom-object/ChannelsVisibilitySettings';

const useStores = () => {
  const { selectedBusinessUnit, selectedStore } = useStoreAndBusinessUnits();
  const { defaultBusinessUnit } = useBusinessUnits();

  const businessUnit = selectedBusinessUnit ?? defaultBusinessUnit;
  const mappedBU = businessUnit ? mapBusinessUnit(businessUnit) : undefined;
  const stores = mappedBU?.stores ?? [];

  const customObjectChannelsVisibility = useCustomObjects('channelsVisibilitySettings', 'channels-visibility-list');
  const channelsVisibilitySettings: ChannelVisibility[] = customObjectChannelsVisibility?.data?.value ?? [];
  const channelsVisibilityKeys = channelsVisibilitySettings.map((item) => item.key);

  const defaultStoreKey = typeof window !== 'undefined' ? localStorage.getItem('store') : undefined;
  const defaultStore =
    selectedStore || stores.find((store) => store.key === defaultStoreKey) || (stores[0] as Store | undefined);

  const selectedBusinessDefaultShippingAddress = mappedBU?.addresses?.find((address) => address.isDefaultShipping);

  const selectedBusinessDefaultBillingAddress = mappedBU?.addresses?.find((address) => address.isDefaultBilling);

  const getStoreChannel = async (): Promise<StoreChannels> => {
    if (!selectedStore?.key) return { distributionChannels: [], supplyChannels: [] };

    const result = await sdk.composableCommerce.store.getStoreChannel({
      storeKey: selectedStore.key,
    });

    return result.isError ? { distributionChannels: [], supplyChannels: [] } : (result.data as StoreChannels);
  };

  const { data } = useSWR<StoreChannels>(
    selectedStore?.key ? ['/action/store/getStoreChannel', { storeKey: selectedStore.key }] : null,
    getStoreChannel,
  );

  const distributionChannels = data?.distributionChannels ?? [];
  const priceChannel = distributionChannels?.find((channel) =>
    channel.key ? !channelsVisibilityKeys.includes(channel.key) : undefined,
  );
  const channel = distributionChannels?.find((channel) =>
    channel.key ? channelsVisibilityKeys.includes(channel.key) : undefined,
  );

  const supplyChannels = data?.supplyChannels ?? [];

  return {
    stores,
    defaultStore,
    channel,
    priceChannel,
    supplyChannels,
    selectedBusinessDefaultShippingAddress,
    selectedBusinessDefaultBillingAddress,
    getStoreChannel,
  };
};

export default useStores;
