import { useMemo } from 'react';
import { sdk } from '@/sdk';
import { Category } from '@shared/types/product/Category';
import useSWR from 'swr';
import { QueryProductCategoriesQuery } from '@/sdk/composable-commerce-b2b/types/queries/ProductQueries';

const useGenericCategories = (query: QueryProductCategoriesQuery) => {
  const key =
    query.businessUnitKey && query.storeKey ? ['/action/product/queryCategories', JSON.stringify(query)] : null;

  const { data, error, isLoading } = useSWR(key, () => sdk.composableCommerce.product.queryCategories(query), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const categories = useMemo(() => (data?.isError ? [] : ((data?.data.items ?? []) as Category[])), [data]);

  return useMemo(
    () => ({
      categories,
      isLoading,
      error,
    }),
    [categories, isLoading, error],
  );
};

export const useCategories = (options?: QueryProductCategoriesQuery) => {
  const query: QueryProductCategoriesQuery = options ?? { format: 'tree', limit: 500 };
  return useGenericCategories(query);
};

export const useRootCategories = (options?: QueryProductCategoriesQuery) => {
  const query: QueryProductCategoriesQuery = {
    format: 'flat',
    limit: 200,
    isRootCategory: true,
    ...options,
  };
  return useGenericCategories(query);
};
