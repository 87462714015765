'use client';

import React, { ChangeEvent, useEffect, useState } from 'react';
import { useStoreAndBusinessUnits } from '@/providers/store-and-business-units';
import Typography from '@/components/atoms/typography';
import Input from '@/components/atoms/input';
import PasswordInput from '@/components/atoms/password-input';
import Button from '@/components/atoms/button';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import useAccount from '@/lib/hooks/useAccount';
import useFormat from '@/hooks/useFormat';
import useSwrClearCache from '@/hooks/useSwrClearCache';
import useCustomRouter from '@/hooks/useCustomRouter';
import useStores from '@/lib/hooks/useStores';
import toast from '@/components/atoms/toaster/helpers/toast';
import { Address } from '@shared/types/account';

const AccountPage = () => {
  const { account, changePassword, logout } = useAccount();
  const { translate } = useTranslation();
  const { formatName, formatBusinessUnitAddress } = useFormat();
  const router = useCustomRouter();
  const clearCache = useSwrClearCache();
  const { selectedBusinessDefaultShippingAddress, selectedBusinessDefaultBillingAddress } = useStores();
  const { selectedBusinessUnit } = useStoreAndBusinessUnits();

  const [formData, setFormData] = useState({
    ...account,
    billingAddress: selectedBusinessDefaultBillingAddress
      ? formatBusinessUnitAddress(selectedBusinessDefaultBillingAddress as Address)
      : '',
    shippingAddress: selectedBusinessDefaultShippingAddress
      ? formatBusinessUnitAddress(selectedBusinessDefaultShippingAddress as Address)
      : '',
    name: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const isPasswordValid =
    formData.oldPassword &&
    formData.newPassword &&
    formData.oldPassword !== formData.newPassword &&
    formData.newPassword === formData.confirmPassword;

  const handlePasswordChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const handleChangePassword = async () => {
    if (isPasswordValid) {
      const changePasswordResponse = await changePassword(formData.oldPassword, formData.newPassword);
      if (typeof changePasswordResponse === 'string') {
        toast.error(changePasswordResponse);
        return;
      }

      toast.success(translate('account.account.password.updated'));
      setFormData({
        ...formData,
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    }
  };

  const handleLogout = () => {
    logout().then(() => {
      clearCache();
      localStorage.clear();
      router.refresh();
      router.push('/login');
    });
  };

  useEffect(() => {
    setFormData((form) => ({
      ...form,
      billingAddress: selectedBusinessDefaultBillingAddress
        ? formatBusinessUnitAddress(selectedBusinessDefaultBillingAddress as Address)
        : '',
      shippingAddress: selectedBusinessDefaultShippingAddress
        ? formatBusinessUnitAddress(selectedBusinessDefaultShippingAddress as Address)
        : '',
    }));
  }, [selectedBusinessUnit]);

  const containerClassName = 'w-full mt-2';

  return (
    <div className="w-full px-4 py-10 md:w-1/2 md:px-8 lg:w-1/3">
      <Typography classStyle="h3" className="mb-10">
        {translate('account.account.title')}
      </Typography>

      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        label={translate('account.account.charge')}
        readOnly
      />
      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        name="name"
        label={translate('account.account.name')}
        value={formatName(formData.firstName, formData.lastName)}
        readOnly
      />
      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        name="email"
        label={translate('account.account.email')}
        value={formData.email}
        readOnly
      />
      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        name="billingAddress"
        label={translate('account.account.billing.address')}
        value={formData.billingAddress}
        readOnly
      />
      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        name="shippingAddress"
        label={translate('account.account.shipping.address')}
        value={formData.shippingAddress}
        readOnly
      />
      {/* <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        name="type"
        label={translate('account.account.delivery.mode')}
        // value={formData.deliveryMode}
        readOnly
      /> */}
      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        name="type"
        label={translate('account.account.type')}
        readOnly
      />
      <PasswordInput
        inputContainerClassName={containerClassName}
        className="w-full"
        name="oldPassword"
        label={translate('account.account.old.password')}
        placeholder={translate('account.account.old.password.placeholder')}
        value={formData.oldPassword}
        onChange={handlePasswordChange}
      />
      <PasswordInput
        inputContainerClassName={containerClassName}
        className="w-full"
        name="newPassword"
        label={translate('account.account.new.password')}
        placeholder={translate('account.account.new.password.placeholder')}
        value={formData.newPassword}
        onChange={handlePasswordChange}
      />
      <PasswordInput
        inputContainerClassName={containerClassName}
        className="w-full"
        name="confirmPassword"
        label={translate('account.account.confirm.password')}
        placeholder={translate('account.account.confirm.password.placeholder')}
        value={formData.confirmPassword}
        onChange={handlePasswordChange}
      />

      <div className="mt-6 flex flex-col gap-4">
        <Button onClick={handleChangePassword} disabled={!isPasswordValid}>
          {translate('account.account.change.password')}
        </Button>
        <Button onClick={handleLogout}>{translate('account.account.logout')}</Button>
      </div>

      {/* <Typography classStyle="h3" className="my-10">
        {translate('account.account.contact')}
      </Typography>

      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        label={translate('account.account.name')}
        readOnly
      />
      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        label={translate('account.account.phone.number')}
        readOnly
      />
      <Input
        inputContainerClassName={containerClassName}
        className="w-full"
        label={translate('account.account.email')}
        readOnly
      /> */}
    </div>
  );
};

export default AccountPage;
