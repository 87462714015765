import { useCallback } from 'react';
import { sdk } from '@/sdk';

const useSessionStoreAndBusinessUnitKeys = () => {
  const setBusinessUnitAndStoreSessionKeys = useCallback(async (businessUnitKey: string, storeKey: string) => {
    const currentBusinessUnitKey = localStorage.getItem('business-unit');
    const currentStoreKey = localStorage.getItem('store');

    if (currentBusinessUnitKey === businessUnitKey && currentStoreKey === storeKey) {
      return;
    }

    await sdk.composableCommerce.businessUnit.setBusinessUnitAndStoreKeys(
      { businessUnitKey, storeKey },
      { skipQueue: true },
    );

    localStorage.setItem('business-unit', businessUnitKey);
    localStorage.setItem('store', storeKey);
  }, []);

  return { setBusinessUnitAndStoreSessionKeys };
};

export default useSessionStoreAndBusinessUnitKeys;
