'use client';

import React from 'react';
import useTranslation from '@/providers/I18n/hooks/useTranslation';
import CommercialOperationListItem from '@/components/molecules/commercial-operation-list-item';
import SectionHeader from '@/components/molecules/section-header';
import { mapCommercialOperation } from '@/utils/mappers/map-commercial-operation';
import { CommercialOperationListItemProps } from '@/components/molecules/commercial-operation-list-item/types';
import { useRootCategories } from '@/lib/hooks/useCategories';
import { format } from 'date-fns';
import LoadingIcon from '@/components/atoms/loading-icon';
import Typography from '@/components/atoms/typography';
import { useStoreAndBusinessUnits } from '@/providers/store-and-business-units';
import { CommercialOperationListProps } from './types';

const CommercialOperationList = ({ title }: CommercialOperationListProps) => {
  const { translate } = useTranslation();
  const { selectedBusinessUnit, selectedStore } = useStoreAndBusinessUnits();
  const { categories: items, isLoading } = useRootCategories({
    isCommercialOperation: true,
    inDate: format(new Date(), 'yyyy-MM-dd'),
    businessUnitKey: selectedBusinessUnit?.key,
    storeKey: selectedStore?.key,
  });

  const productsTotal = items.map((item) => item.total ?? 0);

  const categories = items
    .map((category, i) => mapCommercialOperation(category, productsTotal[i]))
    .sort((catA, catB) => {
      if (catA.title < catB.title) return -1;
      if (catA.title > catB.title) return 1;
      return 0;
    });

  const commercialOperationsData = categories.map<CommercialOperationListItemProps>((operation) => ({
    title: operation.title,
    products: operation.products ?? [],
    image: { src: operation.image },
    link: operation.link,
  }));

  return (
    <div className="m-4 bg-white-100 lg:mx-7 lg:my-14">
      <SectionHeader title={title} />
      <div className="grid w-full grid-cols-4 justify-stretch gap-8">
        {isLoading ? (
          <LoadingIcon svgWidth={20} svgHeight={20} className={'fill-gray-700'} />
        ) : !commercialOperationsData?.length ? (
          <Typography classStyle="b">{translate('common.no.results.found')}</Typography>
        ) : (
          commercialOperationsData.map((item, index) => (
            <div key={index} className="flex-1 border border-white-400">
              <CommercialOperationListItem {...item} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CommercialOperationList;
