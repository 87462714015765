import { sdk } from '@/sdk';
import { CustomObjectPayload } from '@/sdk/composable-commerce-b2b/types/payloads/CustomObjectPayloads';
import useSWR from 'swr';

export const useCustomObjects = (container: string, key: string) => {
  const { data } = useSWR('/action/customObject/getCustomObject', () =>
    sdk.composableCommerce.customObject.getCustomObject({ container, key }),
  );

  return data?.isError ? undefined : (data?.data as CustomObjectPayload);
};
