'use client';

import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useSessionStoreAndBusinessUnitKeys from '@/lib/hooks/useSessionStoreAndBusinessUnitKeys';
import useBusinessUnits from '@/lib/hooks/useBusinessUnits';
import useStores from '@/lib/hooks/useStores';
import { BusinessUnit } from '@shared/types/business-unit';
import { Store } from '@shared/types/store';
import CategoriesProvider from '../categories';
import { ContextShape } from './types';

const initialState = {} as ContextShape;
export const StoreAndBusinessUnitsContext = createContext(initialState);

const StoreAndBusinessUnitsProvider = ({ children }: React.PropsWithChildren) => {
  const { defaultBusinessUnit, businessUnits } = useBusinessUnits();
  const { defaultStore, stores } = useStores();

  const initialBusinessUnitKey =
    typeof window !== 'undefined' && localStorage.getItem('business-unit')
      ? localStorage.getItem('business-unit')
      : defaultBusinessUnit?.key;
  const initialStoreKey =
    typeof window !== 'undefined' && localStorage.getItem('store') ? localStorage.getItem('store') : defaultStore?.key;

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<BusinessUnit | undefined>(
    initialBusinessUnitKey ? businessUnits.find((bu) => bu.key === initialBusinessUnitKey) : undefined,
  );

  const [selectedStore, setSelectedStore] = useState<Store | undefined>(
    initialStoreKey ? stores.find((st) => st.key === initialStoreKey) : undefined,
  );

  const { setBusinessUnitAndStoreSessionKeys } = useSessionStoreAndBusinessUnitKeys();

  const prevKeys = useRef<{ businessUnitKey?: string; storeKey?: string }>({});

  useEffect(() => {
    const businessUnitKey = selectedBusinessUnit?.key;
    const storeKey = selectedStore?.key;

    if (
      businessUnitKey &&
      storeKey &&
      (prevKeys.current.businessUnitKey !== businessUnitKey || prevKeys.current.storeKey !== storeKey)
    ) {
      prevKeys.current = { businessUnitKey, storeKey };
      void setBusinessUnitAndStoreSessionKeys(businessUnitKey, storeKey);
    }
  }, [selectedBusinessUnit?.key, selectedStore?.key]);

  const memoizedBusinessUnit = useMemo(() => selectedBusinessUnit, [selectedBusinessUnit?.key]);
  const memoizedStore = useMemo(() => selectedStore, [selectedStore?.key]);

  const value = useMemo(
    () => ({
      selectedBusinessUnit: memoizedBusinessUnit,
      setSelectedBusinessUnit,
      selectedStore: memoizedStore,
      setSelectedStore,
    }),
    [memoizedBusinessUnit, memoizedStore],
  );

  return (
    <StoreAndBusinessUnitsContext.Provider value={value}>
      <CategoriesProvider businessUnitKey={memoizedBusinessUnit?.key} storeKey={memoizedStore?.key}>
        {children}
      </CategoriesProvider>
    </StoreAndBusinessUnitsContext.Provider>
  );
};

export default StoreAndBusinessUnitsProvider;

export const useStoreAndBusinessUnits = () => {
  return useContext(StoreAndBusinessUnitsContext);
};
