'use client';

import React, { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { CategoryListItemProps, CommonProps } from '@/providers/cart/types';
import { Category } from '@shared/types/product';
import { useCategoriesByBUContext } from '@/providers/categories';
import { DataSourceProps, ViewModelProps } from '../../types';
import { findCategoryBySlug } from '../../utils';

const ProductSelectionListViewModel = dynamic(() => import('../product-selection-list-view-model'));

const ProductSelectionListClientWrapper = (
  props: Omit<CommonProps, 'products' | 'categories'> &
    Omit<DataSourceProps, 'category'> &
    Omit<ViewModelProps, 'categories' | 'category'>,
) => {
  const { slug } = props;
  const { categories } = useCategoriesByBUContext();

  const category = findCategoryBySlug(categories, slug);

  const mapCategories = useMemo(() => {
    const recursiveMap = (cat: Category[]): CategoryListItemProps[] =>
      cat
        .reduce<CategoryListItemProps[]>((acc, c) => {
          if (!c.isCommercialOperation) {
            acc.push({
              id: c.categoryId ?? '',
              name: c.name ?? '',
              slug: c.slug ?? '',
              parentId: c.parentId ?? '',
              isCommercialOperation: c.isCommercialOperation,
              subCategories: recursiveMap(c.subCategories), // Recursive call
            });
          }
          return acc;
        }, [])
        .sort((catA, catB) => (catA.name < catB.name ? -1 : catA.name > catB.name ? 1 : 0));
    return recursiveMap(categories ?? []);
  }, [categories]);

  return <ProductSelectionListViewModel {...props} categories={mapCategories} category={category} />;
};

export default ProductSelectionListClientWrapper;
